<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3 pt-3">
        A separatory funnel contains two immiscible liquids. Using the information provided in the
        table below, which of the two liquids would be the bottom layer in the separatory funnel?
      </p>

      <v-simple-table style="max-width: 300px" class="pl-8">
        <thead>
          <tr>
            <td class="text-left text-bold pr-2">Solvent</td>
            <td class="text-center text-bold">Density (g/mL)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="pr-2">Water</td>
            <td class="text-center">0.998</td>
          </tr>
          <tr>
            <td class="pr-2">{{ otherSolvent.solvent }}</td>
            <td class="text-center">{{ otherSolvent.density }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-n6">
        <v-radio class="my-1" :value="optionValues.water">
          <template #label> Water </template>
        </v-radio>

        <v-radio class="my-1" :value="optionValues.solvent">
          <template #label>
            <stemble-latex :content="otherSolvent.solvent" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q10',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      optionValues: {
        water: 'water',
        solvent: 'solvent',
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value ?? 1;
    },
    otherSolvent() {
      if (this.versionNumber === 1) {
        return {solvent: 'Hexanes', density: '0.661'};
      } else if (this.versionNumber === 2) {
        return {solvent: 'Butanol', density: '0.810'};
      } else if (this.versionNumber === 3) {
        return {solvent: 'Dichloromethane', density: '1.33'};
      } else if (this.versionNumber === 4) {
        return {solvent: 'Diethyl ether', density: '0.713'};
      } else {
        return {solvent: 'error', density: 'error'};
      }
    },
  },
};
</script>
